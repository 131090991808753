import React from 'react'

const Youtube = () => {
  return (
    <div>
      <div className="container-xxl py-5" id="g2">
        <div className="container">
          <div className="row g-5 " id="youtubevideo">
            <div
              id="myCarousel"
              className="carousel slide"
              data-ride="carousel"
              data-interval="0"
            >
              <ol className="carousel-indicators" id="indicator">
                <li
                  data-target="#myCarousel"
                  data-slide-to="0"
                  className="active"
                ></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
              </ol>

              <div className="carousel-inner">
                <div className="item carousel-item active">
                  <div className="row">
                    <div className="col-sm-4">

                      <iframe id="iframe1"
                        srcDoc="
        <style>
            body, .full {
                width: 100%;
                height: 100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
                 overflow: hidden; 
            }
        </style>
        <a
            href='https://www.youtube.com/embed/VxUSHDLyTho?autoplay=1'
            className='full'
        >
            <img
                src='https://vumbnail.com/VxUSHDLyTho.jpg'
                className='full'
            />
            <svg
                version='1.1'
                viewBox='0 0 68 48'
                width='68px'
                style='position: relative;'
            >
                <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                <path d='M 45,24 27,14 27,34' fill='#fff'></path>
            </svg>
        </a>
    "

                        frameBorder="0"
                      ></iframe>
                    </div>

                    <div className="col-sm-4">

                      <iframe id="iframe1"
                        srcDoc="
        <style>
            body, .full {
                width: 100%;
                height: 100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
                overflow: hidden; 
            }
        </style>
        <a
            href='https://www.youtube.com/embed/APDee5jTGf4?start=2?autoplay=1'
            className='full'
        >
            <img
                src='https://vumbnail.com/APDee5jTGf4.jpg'
                className='full'
            />
            <svg
                version='1.1'
                viewBox='0 0 68 48'
                width='68px'
                style='position: relative;'
            >
                <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                <path d='M 45,24 27,14 27,34' fill='#fff'></path>
            </svg>
        </a>
    "

                        frameBorder="0"
                      ></iframe>
                    </div>
                    <div className="col-sm-4">

                      <iframe id="iframe1"
                        srcDoc="
        <style>
            body, .full {
                width: 100%;
                height: 100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
                overflow: hidden; 
            }
        </style>
        <a
            href='https://www.youtube.com/embed/XRghryoXxuU?autoplay=1'
            className='full'
        >
            <img
                src='https://vumbnail.com/XRghryoXxuU.jpg'
                className='full'
            />
            <svg
                version='1.1'
                viewBox='0 0 68 48'
                width='68px'
                style='position: relative;'
            >
                <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                <path d='M 45,24 27,14 27,34' fill='#fff'></path>
            </svg>
        </a>
    "

                        frameBorder="0"
                      ></iframe>
                    </div>
                  </div>
                </div>

                <div className="item carousel-item">
                  <div className="row">
                    <div className="col-sm-4">


                      <iframe id="iframe1"
                        srcDoc="
        <style>
            body, .full {
                width: 100%;
                height: 100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
                overflow: hidden; 
            }
        </style>
        <a
            href='https://www.youtube.com/embed/ICRcTZiAtN4?autoplay=1'
            className='full'
        >
            <img
                src='https://vumbnail.com/ICRcTZiAtN4.jpg'
                className='full'
            />
            <svg
                version='1.1'
                viewBox='0 0 68 48'
                width='68px'
                style='position: relative;'
            >
                <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                <path d='M 45,24 27,14 27,34' fill='#fff'></path>
            </svg>
        </a>
    "

                        frameBorder="0"
                      ></iframe>

                    </div>
                    <div className="col-sm-4">

<iframe id="iframe1"
  srcDoc="
<style>
body, .full {
width: 100%;
height: 100%;
margin: 0;
position: absolute;
display: flex;
justify-content: center;
object-fit: cover;
overflow: hidden; 
}
</style>
<a
href='https://www.youtube.com/embed/VxUSHDLyTho?autoplay=1'
className='full'
>
<img
src='https://vumbnail.com/VxUSHDLyTho.jpg'
className='full'
/>
<svg
version='1.1'
viewBox='0 0 68 48'
width='68px'
style='position: relative;'
>
<path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
<path d='M 45,24 27,14 27,34' fill='#fff'></path>
</svg>
</a>
"

  frameBorder="0"
></iframe>
</div>

<div className="col-sm-4">

<iframe id="iframe1"
  srcDoc="
<style>
body, .full {
width: 100%;
height: 100%;
margin: 0;
position: absolute;
display: flex;
justify-content: center;
object-fit: cover;
overflow: hidden; 
}
</style>
<a
href='https://www.youtube.com/embed/APDee5jTGf4?start=2?autoplay=1'
className='full'
>
<img
src='https://vumbnail.com/APDee5jTGf4.jpg'
className='full'
/>
<svg
version='1.1'
viewBox='0 0 68 48'
width='68px'
style='position: relative;'
>
<path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
<path d='M 45,24 27,14 27,34' fill='#fff'></path>
</svg>
</a>
"
frameBorder="0"></iframe>
</div>
                  </div>
                </div>
              </div>

              <a
                className="carousel-control-prev"
                href="#myCarousel"
                data-slide="prev" rel="noopener noreferrer"
              >
                <i className="fa fa-angle-left" id="leftright" style={{ color: "darkgrey" }}></i>
              </a>
              <a
                className="carousel-control-next"
                href="#myCarousel"
                data-slide="next" rel="noopener noreferrer"
              >
                <i className="fa fa-angle-right" id="rightleft" style={{ color: "darkgrey" }}></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Youtube;

// import React from 'react';

// const Youtube = () => {
//   return (
//     <div className="container-xxl py-5" id="g2">
//       <div className="container">
//         <div className="row g-5" id="youtubevideo">
//           <div
//             id="myCarousel"
//             className="carousel slide"
//             data-ride="carousel"
//             data-interval="0"
//           >
//             <div className="carousel-inner">
//               {/* Repeat for each video */}
//               <div className="carousel-item active">
//                 <div className="row">
//                   {/* Adjust column sizes for responsiveness */}
//                   <div className="col-lg-4 col-md-6">
//                     <iframe
//                       title="YouTube Video 1"
//                       src="https://www.youtube.com/embed/VxUSHDLyTho?autoplay=1"
//                       frameBorder="0"
//                       allowFullScreen
//                     ></iframe>
//                   </div>
//                   <div className="col-lg-4 col-md-6">
//                     <iframe
//                       title="YouTube Video 2"
//                       src="https://www.youtube.com/embed/APDee5jTGf4?start=2?autoplay=1"
//                       frameBorder="0"
//                       allowFullScreen
//                     ></iframe>
//                   </div>
//                   <div className="col-lg-4 col-md-6">
//                     <iframe
//                       title="YouTube Video 3"
//                       src="https://www.youtube.com/embed/XRghryoXxuU?autoplay=1"
//                       frameBorder="0"
//                       allowFullScreen
//                     ></iframe>
//                   </div>
//                 </div>
//               </div>
//               {/* Add more carousel-items for additional videos */}
//             </div>

//             <a
//               className="carousel-control-prev"
//               href="#myCarousel"
//               data-slide="prev"
//               rel="noopener noreferrer"
//             >
//               <i className="fa fa-angle-left" id="leftright" style={{ color: "darkgrey" }}></i>
//             </a>
//             <a
//               className="carousel-control-next"
//               href="#myCarousel"
//               data-slide="next"
//               rel="noopener noreferrer"
//             >
//               <i className="fa fa-angle-right" id="rightleft" style={{ color: "darkgrey" }}></i>
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// import React from 'react';

// const Youtube = () => {
//   return (
//     <div className="container-xxl py-5" id="g2">
//       <div className="container">
//         <div className="row g-5" id="youtubevideo">
//           <div
//             id="myCarousel"
//             className="carousel slide"
//             data-ride="carousel"
//             data-interval="0"
//           >
//             <div className="carousel-inner">
//               {/* Each video in a carousel item */}
//               <div className="carousel-item active">
//                 <div className="d-flex justify-content-center">
//                   <iframe
//                     title="YouTube Video 1"
//                     src="https://www.youtube.com/embed/VxUSHDLyTho?autoplay=1"
//                     frameBorder="0"
//                     allowFullScreen
//                     width="300" // Adjust the width as needed
//                     height="200" // Adjust the height as needed
//                   ></iframe>
//                 </div>
//               </div>
//               <div className="carousel-item">
//                 <div className="d-flex justify-content-center">
//                   <iframe
//                     title="YouTube Video 2"
//                     src="https://www.youtube.com/embed/APDee5jTGf4?start=2?autoplay=1"
//                     frameBorder="0"
//                     allowFullScreen
//                     width="300" // Adjust the width as needed
//                     height="200" // Adjust the height as needed
//                   ></iframe>
//                 </div>
//               </div>
//               <div className="carousel-item">
//                 <div className="d-flex justify-content-center">
//                   <iframe
//                     title="YouTube Video 3"
//                     src="https://www.youtube.com/embed/XRghryoXxuU?autoplay=1"
//                     frameBorder="0"
//                     allowFullScreen
//                     width="300" // Adjust the width as needed
//                     height="200" // Adjust the height as needed
//                   ></iframe>
//                 </div>
//               </div>
//             </div>

//             {/* Carousel Controls */}
//             <button
//               className="carousel-control-prev"
//               type="button"
//               data-bs-target="#myCarousel"
//               data-bs-slide="prev"
//             >
//               <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//               <span className="visually-hidden">Previous</span>
//             </button>
//             <button
//               className="carousel-control-next"
//               type="button"
//               data-bs-target="#myCarousel"
//               data-bs-slide="next"
//             >
//               <span className="carousel-control-next-icon" aria-hidden="true"></span>
//               <span className="visually-hidden">Next</span>
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };



// import React from 'react';


// const Youtube = () => {
//   // Function to handle next slide on desktop screens
//   const handleNextSlide = () => {
//     const myCarousel = document.getElementById('myCarousel');
//     const activeIndex = Number(myCarousel.querySelector('.carousel-item.active').getAttribute('data-slide-index'));
//     const itemCount = myCarousel.querySelectorAll('.carousel-item').length;
//     if (activeIndex < itemCount - 1) {
//       myCarousel.querySelector(`[data-slide-to="${activeIndex + 1}"]`).click();
//     }
//   };

//   return (
//     <div className="container-xxl py-5" id="g2">
//       <div className="container">
//         <div className="row g-5" id="youtubevideo">
//           <div
//             id="myCarousel"
//             className="carousel slide"
//             data-bs-interval="0"
//           >
//             <div className="carousel-inner">
//               <div className="carousel-item active">
//                 <div className="row">
//                   <div className="col-lg-4 d-none d-lg-block">
//                     {/* Video 1 */}
//                     <iframe
//                       title="YouTube Video 1"
//                       src="https://www.youtube.com/embed/VxUSHDLyTho?autoplay=1"
//                       frameBorder="0"
//                       allowFullScreen
//                       width="300" // Adjust the width as needed
//                       height="200" // Adjust the height as needed
//                     ></iframe>
//                   </div>
//                 </div>
//               </div>
//               {/* Add more carousel items for additional videos */}
//               <div className="carousel-item">
//                 <div className="row">
//                   <div className="col-lg-4 d-none d-lg-block">
//                     {/* Video 2 */}
//                     <iframe
//                       title="YouTube Video 2"
//                       src="https://www.youtube.com/embed/APDee5jTGf4?autoplay=1"
//                       frameBorder="0"
//                       allowFullScreen
//                       width="300" // Adjust the width as needed
//                       height="200" // Adjust the height as needed
//                     ></iframe>
//                   </div>
//                 </div>
//               </div>
//               <div className="carousel-item">
//                 <div className="row">
//                   <div className="col-lg-4 d-none d-lg-block">
//                     {/* Video 3 */}
//                     <iframe
//                       title="YouTube Video 3"
//                       src="https://www.youtube.com/embed/XRghryoXxuU?autoplay=1"
//                       frameBorder="0"
//                       allowFullScreen
//                       width="300" // Adjust the width as needed
//                       height="200" // Adjust the height as needed
//                     ></iframe>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Carousel Controls */}
//             <button
//               className="carousel-control-prev"
//               type="button"
//               data-bs-target="#myCarousel"
//               data-bs-slide="prev"
//             >
//               <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//               <span className="visually-hidden">Previous</span>
//             </button>
//             <button
//               className="carousel-control-next"
//               type="button"
//               onClick={handleNextSlide} // Handle next slide click for desktop
//             >
//               <span className="carousel-control-next-icon" aria-hidden="true"></span>
//               <span className="visually-hidden">Next</span>
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Youtube;











