import Path from "./Path";
function App() {

  return (
    
    <div>
    
      <Path />
    </div>
  );
}

export default App;


